import React from 'react'
import { Col, Row } from 'react-flexbox-grid'

class Success extends React.Component {
  render() {
    const props = this.props
    const address = `https://${props.domainName}.lighttag.io/`
    return (
      <Row>
        <Col xs={12} md={6}>
          <h1>Welcome aboard.</h1>
          <h2> Your LightTag site is loading and will soon be available at </h2>
          <h3>
            {' '}
            <a href={address}>{address}</a>
          </h3>
        </Col>
        <Col xs={12} md={6}>
          <div css={{ marginTop: '25%' }}>
            You'll receive an email to {props.email} from us as soon as you're
            site is ready, usually in 10 minutes or less.
          </div>
        </Col>
      </Row>
    )
  }
}

export default Success
